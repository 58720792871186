.main-content {
    border: 1px black solid;
    display: flex;
    flex-direction: row;
    flex: 5;

    .sidebar{
        background: blueviolet;
        flex: 1;
    }

    .menu-content{
        background: brown;
        flex: 5;
    }
}

.footnote{
    background: chartreuse;
    flex: 1;
}
